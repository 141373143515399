import React, { Component } from 'react'
/* import Container from '@material-ui/core/Container'; */
import APPIpic from '../../../APPIpic';
import TextToHtml from './../../../Texthtml';
import { ConstantsNames } from "../../../Urls"
import OwlCarousel from 'react-owl-carousel';

import geresh from '../../../../img/icons/geresh.svg'

export default class About extends Component {


    constructor(props) {
        super(props);

        this.state = {
            showLess: true
        }
    }

    render() {


        let page = this.props.pageData.page;
        //console.log(page);

        return (
            <div className='AboutPage'>

                <section className='aboutUp' >

                    <img src={geresh} alt='גרש' className='geresh' />

                    <div className={`textConont ${this.state.showLess ? 'less' : ''} clear`}>
                        <h2>{page.titleUp}</h2>
                        <TextToHtml text={page.text_up} format="" NameClass="text" />
                    </div>

                    <button className='moreLessBtn' onClick={() => this.setState({ showLess: !this.state.showLess })}>{this.state.showLess ? 'קראו עוד' : 'סגירה'}</button>


                </section>

                <div className='picDown'>
                    <strong className='superStrong'>{page.sign.replace(' | ', ' ')}</strong>
                    <img src={ConstantsNames.pic2 + '/pages/mobileBg.jpg?v=2'} className='fullWidth' alt={this.props.pageData.page.h1} />
                </div>



                <h1 className='pageTitle hidden' >{this.props.pageData.page.h1}</h1>

                <div className='gallery'>

                    <OwlCarousel
                        style={{ direction: 'ltr' }}
                        loop={true}
                        stagePadding={110}
                        autoPlay={true}
                        items={1}
                        margin={60}
                        nav={false}
                        dots={false}
                        lazyLoad={true}
                    >

                        <div className="item" key={1}>
                            <figure>
                                <APPIpic fullWidth={true} pic={page.pic_eli} alt='אלי אביסרור' />
                                <figcaption>אלי אביסרור<br />מנכ״ל</figcaption>
                            </figure>
                        </div>

                        <div className="item" key={2}>
                            <figure>
                                <APPIpic fullWidth={true} pic={page.pic_itzhak} alt="יצחק (ג'קי) אביסרור" />
                                <figcaption>יצחק (ג'קי) אביסרור<br />סמנכ"ל</figcaption>
                            </figure>
                        </div>

                        <div className="item" key={3}>
                            <figure>
                                <APPIpic fullWidth={true} pic={page.pic_yoram} alt='יורם אביסרור' />
                                <figcaption>יורם אביסרור<br />סמנכ"ל שיווק</figcaption>
                            </figure>
                        </div>

                    </OwlCarousel>

                </div>

                <div className='texts clear'>


                    <div className='text'>
                        <TextToHtml text={page.text1} format="" />
                    </div>


                    <div className='text'>
                        <TextToHtml text={page.text2} format="" />

                        {page.IframeBook ? <a className='toBtn toCatalog' href={page.IframeBook} rel='noopener noreferrer' target='_blank'>לצפייה בקטלוג דיגיטלי</a> : false}
                    </div>



                </div>


            </div>
        )
    }
}
