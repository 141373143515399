import React, { Component } from 'react'
import HomeProjects from './../HomePage/HomeProjects';
import ShowPopUp from "./../../ShowPopUp"

export default class Projects extends Component {
   
    render() {


        return (
            <div className='Projects'>

                < ShowPopUp dataPopup={this.props.pageData.popUp} />

                <section className="HomeProjects" id='HomeProjects'>
                    <div className="HomeProjectsWrap">
                    <h1 style={{textAlign: 'center' }} >{this.props.pageData.page.h1}</h1>
                        <HomeProjects {...this.props} />
                    </div>
                </section>
            </div>
        )
      
    }
}
