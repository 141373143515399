import React, { Component } from 'react'
//import TextToHtml from '../../../Texthtml';
import APPIpic from '../../../APPIpic';

//sections
import HomeProjects from './../../desktop/HomePage/HomeProjects';
import Container from '@material-ui/core/Container';

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */

//import w_whatsapp from './../../../../img/icons/white/whatsapp.svg';
import Dropdown from 'react-bootstrap/Dropdown'

import BulletsNumbers from './../../desktop/HomePage/BulletsNumbers';
import Carousel from 'react-bootstrap/Carousel' /* https://react-bootstrap.github.io/components/carousel/ */

import { NavLink } from 'react-router-dom'; 
import calcIcon from './../../../../img/icons/calc.svg';
import acc_calcIcon from './../../../../img/icons/acc_calc.svg';

export default class HomePage extends Component {

    render() {

        let page = this.props.pageData.page;
        let mishtaken = '/פרויקטים/משתכן';
        let PagesNames = {
            calc: [{
              url:'/' + this.props.infoSite.SiteDataitems.pages[11].seo.friendly,
              namePage : this.props.infoSite.SiteDataitems.pages[11].title
            }]
          }

        let counterData = {
            'dirot_counter' :  parseInt(page.dirot_counter),
            'proj_counter' :  parseInt(page.proj_counter),
            'city_counter' :  parseInt(page.city_counter)
        }

        return (
            
            <div className="HomePage animated fadeIn">
                {/* https://material-ui.com/es/api/container/ */}
                
                <NavLink className="OpenCalc" activeClassName='is-active' to={PagesNames.calc[0].url}>
                    <img className="regular" src={calcIcon} alt="לחצו לחישוב משכנתא" />
                    <img className="acc" src={acc_calcIcon} alt="לחצו לחישוב משכנתא" />
                </NavLink>

                <section className="section1">
                    <h1>{page.h1}</h1>

                    <nav className="sec1Buttons">
                        
                        <Dropdown className='btnStyle'>
                            <Dropdown.Toggle variant="default" id="dropdown-basic">פרויקטים</Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item role="menuitem" onClick={() => scroll.scrollTo(0)} href={'/' + this.props.infoSite.SiteDataitems.pages[5].seo.friendly} >מגורים</Dropdown.Item>
                                <Dropdown.Item role="menuitem" onClick={() => scroll.scrollTo(0)} href={'/' + this.props.infoSite.SiteDataitems.pages[7].seo.friendly}>מסחרי</Dropdown.Item>
                                <Dropdown.Item role="menuitem" onClick={() => scroll.scrollTo(0)} target="_blank" href={mishtaken} >למשתכן</Dropdown.Item>
                                {/*<Dropdown.Item href='https://lamishtaken.avisror.com/' rel='noopener noreferrer' target='_blank'>למשתכן</Dropdown.Item>*/}
                            </Dropdown.Menu>
                        </Dropdown>


                        {/* <a className="whatsapp btnStyle" role="menuitem" href={'https://api.whatsapp.com/send?phone=' + this.props.infoSite.SiteDataitems.settings.whatsappRobot} rel='noopener noreferrer' target='_blank'>
                            <span>מצא דירה</span>
                            <img className="white" src={w_whatsapp} alt='whatsapp' />
                        </a> */}
                    </nav>

                    <Carousel indicators={false} controls={false} interval={this.props.accesStates.motionOffClass === 'motionOn' ? 4000 : null } fade={true}>
                            <Carousel.Item >
                                <APPIpic fullWidth={true} pic={page.m_home1} alt='תמונת אווירה'/>
                            </Carousel.Item>
                            <Carousel.Item >
                                <APPIpic fullWidth={true} pic={page.m_home2} alt='תמונת אווירה'/>
                            </Carousel.Item>
                    </Carousel>
                    
                </section>
                
                <section className="bulletsSec">
                    <Container maxWidth="lg" >
                        <BulletsNumbers accesStates={this.props.accesStates} counterData={counterData} />
                    </Container>
                </section>

                <section className="HomeProjects" id='HomeProjects'>
                    <Container maxWidth="lg" >
                       <HomeProjects {...this.props} />
                    </Container>
                </section>
                
            </div>
        )
    }
}
