import React, { Component } from 'react'
import HomeProjects from './../../desktop/HomePage/HomeProjects';

export default class Projects extends Component {
   
    render() {

        return (
            <div className='Projects'>
                <section className="HomeProjects" id='HomeProjects'>
                    <div className="HomeProjectsWrap">
                        <h1 style={{
                            textAlign: 'left',
                            fontSize: '20px',
                            top: '0',
                            width: '100%',
                            position: 'absolute',
                            paddingLeft: '5%', 
                            margin: '26% 0 0 0' 
                            }} >{this.props.pageData.page.h1}</h1>
                        <HomeProjects {...this.props} />
                    </div>
                </section>
            </div>
        )
      
    }
}
