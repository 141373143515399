import React, { Component } from 'react'
import FormConnector from './../../../../Components/forms/FormConnector';
import TextToHtml from './../../../../Components/Texthtml';
import ShowNavElement from './ShowNavElement';
import { ConstantsNames } from "../../../Urls"

import ShowMap from './ShowMap';

import ReactPlayer from 'react-player'

import APPIpic from "./.././../../APPIpic";
/* import Owl from "./.././../../Owl"; */

import MaterialGallery from "./.././../../MaterialGallery";
import ShowPlans from "./ShowPlans";
import MoreProjects from "./MoreProjects";

import {StatusProj} from '../../../StatusProj';


import whatsapp from './../../../../img/icons/whatsapp.svg';

//import NavProjects from './NavProjects.js';
import ShowPopUp from "./../../ShowPopUp"    
import ShowWhastapp from './ShowWhastapp';


export default class ProjectView extends Component {

    showGallery = (data,text) => {
        //console.log(text);
        if(data[0].data && text.textOnGallery) {
            return(
                <section className='gallery'>
                    <h2 className='textOnGallery'  style={{backgroundColor: text.colorBgGalleryText }}  >{text.textOnGallery}</h2>
                    < MaterialGallery motion={this.props.accesStates.motionOffClass} pictures={data} />
                </section>
            );
        } else if(data[0].data) {
            return(
                <section className='gallery'>
                    < MaterialGallery pictures={data} />
                </section>
            );
        } else return false;
    }

    showVideo = (data) => {
        //console.log(data)
        if(data.length > 5) {
            return(
                <section className='video'  >

                    <h2>סרטון על הפרויקט</h2>

                    {/* https://www.npmjs.com/package/react-player */}
                    
                    <ReactPlayer
                            className='projVideo'
                            /* width='900px' */
                            width='100%'
                            height='500px'
                            showinfo='0'
                            url={data}
                            light = {true}
                            config={{youtube: {showinfo: 0,playerVars: {showinfo: 0,rel: 0,controls: true,autoplay: true}}}} />
                </section>
            );
        } else return false;
    }

    showMap = (data) => {

        var cords = data.map_place.split(',');
        //console.log(res);

        //console.log(data)
        if(data.map_place.length > 5) {
            return(
                <section className='map'  >

                    <h2>מיקום הפרויקט</h2>
                    <p className='textPlace'>{data.place_text}</p>

                    <div className="MapShow" >
                        <ShowMap width='900px' height='500px' data={data} lat={parseFloat(cords[0])} long={parseFloat(cords[1])} />
                    </div>

                </section>
            );
        } else return false;
    }

    
    showCustomBullets = (data) => {

        //console.log(data)

        if(data.bullets_advantage.length > 5) {
            return(
                <section className='bullets_advantage' >

                    <h2>יתרונות הפרויקט</h2>
                    <div className='bullets'>
                        <TextToHtml text={data.bullets_advantage} format=""/>
                    </div>

                </section>
            );
        } else return false;
    }
    
    showTechnics = (data) => {

        //console.log();

        let Background = ConstantsNames.pic + this.props.pageData.page.bgTechno;
      
        if(data.technics.length > 5) {
            return(

                <section className='technics' style={{backgroundImage: "url(" + Background + ")" }} >
                    <h2>מפרט טכני</h2>
                    <TextToHtml text={data.technics} format=""/>
                </section>
            );
        } else return false;
    }
  

    showPlans = (pPlans,pPlansRooms) => {
        //console.log(pPlans);
        //console.log(pPlansRooms);
        if(pPlans[0].data) {
            return(
                <section className='plans'>
                    <h2>לתכניות דירה</h2>
                    < ShowPlans plans={pPlans} rooms={pPlansRooms} />
                </section>
            );
        } else return false;
    }
    

    render() {

        let pData = this.props.pageData.project.info;
        let pPictures = this.props.pageData.project.pictures;
        let pPlans = this.props.pageData.project.plans;
        let pPlansRooms = this.props.pageData.project.plansRooms;

        /* console.log(this.props.pageData); */
        //console.log(this.props);
        /*if(pData.data.statusProj==='פרויקטים למשתכן') {
            window.open('https://lamishtaken.avisror.com/','_blank');
        }*/

        //console.log(this.props.pageData.popUp);

        return (
            <div className={'projectView animated fadeIn projId' + pData.data.id } >

                <ShowWhastapp projectId = {pData.data.id} />

                < ShowPopUp dataPopup={this.props.pageData.popUp} />

                <div className='tableProject'>
                    <div className='navProj col'>
                        <div className='statusProj'>{StatusProj(pData.data.statusProj)}</div>
                        <header>
                            <h1 className='hidden'>{pData.data.name}</h1>
                            <APPIpic showclass='logo' pic={pData.data.logo} alt={pData.data.name} />
                            <h2>{pData.data.slogan}</h2>
                            <div className='text'>
                                <TextToHtml text={pData.data.description} format=""/>
                            </div>
                            
                            <ul className='navigateElements'>
                                
                                <ShowNavElement condition={pPictures[0].data ? true : false } to='gallery' name='גלריה' />
                                <ShowNavElement condition={pData.data.video ? true : false } to='video' name='וידאו' />
                                <ShowNavElement condition={pData.data.map_place ? true : false } to='map' name='מיקום הפרויקט' />
                                <ShowNavElement condition={pData.data.technics ? true : false } to='technics' name='מפרט טכני' />
                                <ShowNavElement condition={pPlans[0].data ? true : false } to='plans' name='תכנית העמדה' />

                                <ShowNavElement condition={pData.data.catalogIframe ? true : false } to='catalogIframe' name='לצפייה בקטלוג דיגיטלי' />

                                <ShowNavElement condition={ true } to='more' name='פרויקטים נוספים באזור' />
                                { pData.data.catalog || pData.data.whatsapp ? <li className='toCatalog'>
                                    { pData.data.catalog ? <a href={ConstantsNames.files + pData.data.catalog} rel='noopener noreferrer' download="" target='_blank'>להורדת הקטלוג</a> : false}
                                    { pData.data.whatsapp ? 
                                        <a className="whatsapp" role="menuitem" href={'https://api.whatsapp.com/send?phone=' + pData.data.whatsapp} rel='noopener noreferrer' target='_blank'>
                                        <span>לשיחה עם נציג</span>
                                            <img className="white" src={whatsapp} alt='whatsapp' />
                                        </a> : false}
                                    </li> : false}                                
                            </ul>
                        </header>
                    </div>
                    <div className='infoProject col'>
                        
                        {this.showGallery(pPictures,pData.data)}
                        
                        
                        <section className='formSec'>
                            <TextToHtml text={this.props.infoSite.SiteDataitems.settings.form_titleProjects} format="h2"/>
                            <h3 className='formMustText'>{this.props.infoSite.SiteDataitems.settings.formMustText}</h3>
                            <FormConnector pageData={this.props.pageData} infoSite={this.props.infoSite}/>
                        </section>

                        {this.showCustomBullets(pData.data)}

                        {this.showVideo(pData.data.video)}

                        {this.showMap(pData.data)}

                        {this.showTechnics(pData.data)}

                        {this.showPlans(pPlans,pPlansRooms)}


                        {pData.data.catalogIframe ? <iframe title='catalogIframe' className='catalogIframe' src={pData.data.catalogIframe} /> : false }

                        <section className='more'>
                            <h2>פרויקטים נוספים באזור</h2>

                            <MoreProjects {...this.props} config={{items: 3, loop: false, margin: 10, nav: true, dots: true}} />
                        </section>


                        
                    </div>
                </div>

                
            </div>
        )
    }
}


