import React, { Component } from 'react'
import ShowPopUp from "./../../ShowPopUp"    
import TextToHtml from './../../../../Components/Texthtml';

import downMouse from './../../../../img/icons/downMouse.svg';
import UrbanRenewalItem from './UrbanRenewalItem';
import logoCity from '../../../../img/default/logoCity.svg'

export default class UrbanRenewal extends Component {
    render() {

        let is_mobile = this.props.info.media === 'desktop' ? false : true;
        //let pData = this.props.pageData;
        let pageGeneral = this.props.pageData.page;
        let projectsUrban = this.props.pageData.projectsUrban;

        //console.log(pData);
        //console.log(projectsUrban);

        return (
            <div className={'projectView urbanRenewalPage animated fadeIn'} >

                < ShowPopUp dataPopup={this.props.pageData.popUp} />

                <div className='tableProject'>
                    <div className='navProj col'>
                        <header>
                            <img className='logoCity' src={logoCity} alt={pageGeneral.h1} />
                            <h1 className='hidden'>{pageGeneral.h1}</h1>
                            <hr/>
                            <div className='text'>
                                <TextToHtml text={pageGeneral.generalText} format=""/>
                                <img className='downMouse' src={downMouse} alt='לגלול למטה' />
                            </div>
                        </header>
                    </div>
                    <div className='infoProject col'>
                        {projectsUrban && projectsUrban[0].data ? <div className='urbanProjects'>
                            {projectsUrban.map(item => { 
                                
                                let class1 = item.data.pic1 ? 'is_pic' : 'no_pic';
                                let class2 = item.data.logos ? 'have_logos' : 'donthave_logos';

                                let classItem = class1 + ' ' + class2;

                                if (true) return <div key={item.data.id} className={'urbanProject  clear ' + classItem }>
                                <UrbanRenewalItem item={item} is_mobile = {is_mobile} />
                            </div> 
                                else return false}) }
                        </div> : <div className='urbanProjects'><h2>אין פרויקטים</h2></div> }
                        
                    </div>
                </div>

                
            </div>
        )
    }
}
