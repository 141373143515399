import React, { Component } from 'react'

import ContentWrapper  from './Components/ContentWrapper';
import Nav  from './Components/pages/desktop/Nav';
import NavEng  from './Components/pages/desktop/NavEng';
import Footer from './Components/pages/desktop/Footer';

import Accessibility from './Components/Accessibility';

import './css/animate.min.css'; /* https://daneden.github.io/animate.css/ */
import './css/site.scss?v=1';
import './css/site_pages.scss?v=1';
import './css/media.css?v=1';
import './css/accessibility.css?v=1';

export default class Desktop extends Component {


    render() {

        

        return (
            <div className={"body-container " + this.props.pageClass}>
                
                <Accessibility  info={this.props.info} accessibility={this.props.accessibility} accesStates={this.props.accesStates}/>

                {this.props.page === 'welcome/en' ? <NavEng info={this.props.info} className="element" /> : <Nav info={this.props.info} className="element" />}
                
                <div className="content-wrapper">
                    <ContentWrapper accesStates={this.props.accesStates} mishtaken={this.props.mishtaken ? true : false } page={this.props.page} info={this.props.info} friendProj={this.props.friendProj} />
                </div>

                <Footer {...this.props}/>

            </div>
        )
    }
}
