import React, { Component } from 'react'
//import ShowUsers  from './ShowUsers';

import { RestUrls } from "../Components/Urls";
import HomePage  from './pages/desktop/HomePage/HomePage';
import Projects  from './pages/desktop/projects/Projects';
import ProjectView from './pages/desktop/projects/ProjectView';

import UrbanRenewal from './pages/desktop/UrbanRenewal/UrbanRenewal';

import Contact  from './pages/desktop/Contact/Contact';
import Articles  from './pages/desktop/Articles/Articles';
import About  from './pages/desktop/About/About';
import Center  from './pages/desktop/Center/Center';
import Jobs  from './pages/desktop/Jobs/Jobs';
import Calc  from './pages/desktop/Calc/Calc';

//import English  from './pages/desktop/English/EngHomePage';

import Accessibility  from './pages/desktop/Accessibility/Accessibility';
import Seo from './../Seo';

import Page404  from './pages/Page404';


export default class ContentWrapper extends Component {


    constructor(props) {
        super(props);
        
        this.state = {
          items: [],
          isLoaded: false,
        }

        
      }


      async componentDidMount() {
        
        const pageName = this.props.page === 'welcome/project' ? 'welcome/' + this.props.friendProj : this.props.page;
        let API =  RestUrls.Pages + pageName + RestUrls.Code;

        const res = await fetch(API)

        /* console.log(API);
        console.log(res); */

        const data = await res.json();

        this.setState(
            {items: data,  isLoaded: true,}

        )

        //console.log(this.props.items);
    }



    render() {

        //console.log(this.props.idProj);

        let {isLoaded, items } = this.state;

        if (!isLoaded) {
            return <div>טוען...</div>
        }

        else {

        
            switch (this.props.page) {

                case "welcome/index":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <HomePage accesStates={this.props.accesStates} pageData={items} infoSite={this.props.info} />
                        </React.Fragment>
                    )

                case "welcome/projects":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <Projects pageData={items} infoSite={this.props.info} mishtaken={this.props.mishtaken} />
                        </React.Fragment>
                    )

                case "welcome/project":
                return (
                    <React.Fragment>
                        <Seo infoSite={this.props.info} seo={items.project.info.seo} pageData={items} />
                        <ProjectView accesStates={this.props.accesStates} pageData={items} infoSite={this.props.info} idProj={this.props.idProj} />
                    </React.Fragment>
                )

                case "welcome/calc":
                return (
                    <React.Fragment>
                        <Seo infoSite={this.props.info} pageData={items} />
                        <Calc pageData={items} {...this.props} />
                    </React.Fragment>
                )
                

                /* case "ShowUsers":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <ShowUsers />
                        </React.Fragment>
                    ) */

                case "welcome/contact":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <Contact pageData={items} {...this.props} />
                        </React.Fragment>
                    )

                case "welcome/urbanRenewal":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <UrbanRenewal pageData={items} {...this.props} />
                        </React.Fragment>
                    )

                    

                case "welcome/articles":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <Articles pageData={items} {...this.props} />
                        </React.Fragment>
                    )

                case "welcome/about":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <About pageData={items} {...this.props} />
                        </React.Fragment>
                    )


                /*  case "welcome/en":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <English pageData={items} {...this.props} />
                        </React.Fragment>
                    ) */

                case "welcome/center":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <Center pageData={items} {...this.props} />
                        </React.Fragment>
                    )

                case "welcome/jobs":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <Jobs pageData={items} {...this.props} />
                        </React.Fragment>
                    )

                case "welcome/accessibility":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <Accessibility pageData={items} {...this.props} />
                        </React.Fragment>
                    )

                case "welcome/error404Page":
                    return (
                        <React.Fragment>
                            <Seo infoSite={this.props.info} pageData={items} />
                            <Page404 />
                        </React.Fragment>
                    )

                default:
                    return (
                        <div>DEFAULT CASE  Page1</div>
                    )
            }

        }
    }
}
