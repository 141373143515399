import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'; 
import APPIpic from '../../../APPIpic';
import HomeProjects from './HomeProjects';
import BulletsNumbers from './BulletsNumbers';
//import Container from '@material-ui/core/Container';
import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */

import Carousel from 'react-bootstrap/Carousel' /* https://react-bootstrap.github.io/components/carousel/ */

import ShowPopUp from "./../../ShowPopUp"

//import TextToHtml from './../../../../Components/Texthtml';

//import md5 from '@js-md5';

//import w_whatsapp from './../../../../img/icons/white/whatsapp.svg';

export default class HomePage extends Component {

    render() {
        //console.log(localStorage.getItem('path'));

        let page = this.props.pageData.page;

        let counterData = {
                'dirot_counter' :  parseInt(page.dirot_counter),
                'proj_counter' :  parseInt(page.proj_counter),
                'city_counter' :  parseInt(page.city_counter)
            }
        

        return (
            
            <div className="HomePage animated fadeIn">
                
                < ShowPopUp dataPopup={this.props.pageData.popUp} />

                {/* https://material-ui.com/es/api/container/ */}
                
                <section className="section1">
                    <h1>{page.h1}</h1>
                    
                    <nav className="sec1Buttons">
                        <NavLink role="menuitem" activeClassName='is-active'  onClick={() => scroll.scrollTo(0)} to={'/' + this.props.infoSite.SiteDataitems.pages[5].seo.friendly}>פרוייקטים</NavLink>
                        <a href='https://lamishtaken.avisror.com/' rel='noopener noreferrer' target='_blank'>אביסרור למשתכן</a>
                        {/* <a className="whatsapp" role="menuitem" href={'https://api.whatsapp.com/send?phone=' + this.props.infoSite.SiteDataitems.settings.whatsappRobot} rel='noopener noreferrer' target='_blank'>
                            <span>מצא דירה</span>
                            <img className="white" src={w_whatsapp} alt='whatsapp' />
                        </a> */}
                    </nav>
                        
                    <Carousel indicators={false} controls={false} interval={this.props.accesStates.motionOffClass === 'motionOn' ? 4000 : null} fade={true}>
                            <Carousel.Item >
                                <APPIpic fullWidth={true} pic={page.home1} alt='תמונת אווירה'/>
                            </Carousel.Item>
                            <Carousel.Item >
                                <APPIpic fullWidth={true} pic={page.home2} alt='תמונת אווירה'/>
                            </Carousel.Item>
                    </Carousel>
                    
                </section>

                <section className="bulletsSec">
                    <BulletsNumbers accesStates={this.props.accesStates} counterData={counterData} />
                </section>
                
                <section className="HomeProjects" id='HomeProjects'>
                    <div className="HomeProjectsWrap">
                        <HomeProjects {...this.props} />
                    </div>
                    
                </section>
                
            </div>
        )
    }
}
