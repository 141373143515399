import React from 'react'

import whatsapp from '../../../../img/icons/whatsapp.png';
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect

export default function ShowWhastapp(props) {
  
    const {projectId} = props

    let url = null;

    switch (projectId) {

        case '32':

            url = 'https://bit.ly/3ayMbqc';
            break;
    
        default:
            break;
    }

    if(!url) {return false;}

    return (

    <a className='showWhastapp bounce1' href={url} target={isMobile ? "_self" : "_blank"} rel="noopener noreferrer">
        < img src={whatsapp} alt='שיחת WHATSAPP' />
    </a>
  )
}
