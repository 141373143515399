import React, { Component } from 'react'
import { ConstantsNames } from "../../Urls"
import FormConnector from '../../forms/FormConnector';
import TextToHtml from '../../Texthtml';
import Container from '@material-ui/core/Container';
//import { NavLink } from 'react-router-dom'; 

//import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */


import b_facebookIcon from './../../../img/icons/black/facebook.svg';
import b_instagram from './../../../img/icons/black/instagram.svg';
import b_youtube from './../../../img/icons/black/youtube.svg';
//import b_whatsapp from './../../../img/icons/black/whatsapp.svg';

import logo from './../../../img/default/logoMenu.svg';
//import logosDuns from './../../../img/icons/2logosAvisror.jpg';
import logoBDI from './../../../img/icons/2023_2.png';

import dans1 from './../../../img/icons/dans1Grey.png';
import city2023 from './../../../img/icons/logo2023.png';

export default class Footer extends Component {

    
    

    render() {

      
        let site = this.props.info.SiteDataitems;
        let Background = ConstantsNames.pic + site.settings.m_footer_bg;



        //console.log(this.props);

        //let ProjetsLow = this.props.info.SiteDataitems.ProjetsLow;
              
        return (
            <div className="footer-wrapper">
                <footer className="site-footer" >

                    <section className='formSec' style={{backgroundImage: "url(" + Background + ")" }} >
                        <TextToHtml text={this.props.page === 'welcome/center' ? site.settings.m_center_form_titleProjects : site.settings.m_form_title} format="h2"/>
                        <h3 className='formMustText'>{site.settings.formMustText}</h3>
                        <FormConnector pageData={this.props} infoSite={this.props.info} showProjects={this.props.page === 'welcome/center' ? false : true} center={this.props.page === 'welcome/center' ? true:false }/>

                    </section>
                    

                    <section className='downText'>
                        <Container maxWidth="lg" >
                            <div className='siteMapTable'>
                               
                                <div className='col social'>
                                     
                                    <img className='mainLogo' src={logo} alt={this.props.info.SiteDataitems.settings.sitename} />

                                    <div className='text'>
                                        <TextToHtml text={site.settings.m_footer_text} format=""/> 
                                    </div>

                                    {/* <a className='whatsapp' href={'https://api.whatsapp.com/send?phone=' + this.props.info.SiteDataitems.settings.whatsapp} rel='noopener noreferrer' target='_blank'>
                                        <img className="black" src={b_whatsapp} alt='whatsapp' />
                                    </a> */}

                                    <a href={this.props.info.SiteDataitems.settings.instagram} rel='noopener noreferrer' target='_blank'>
                                        <img className="black" src={b_instagram} alt='instagram' />
                                    </a>

                                    <a href={this.props.info.SiteDataitems.settings.facebook} rel='noopener noreferrer' target='_blank'>
                                        <img className="black" src={b_facebookIcon} alt='facebook' />
                                    </a>

                                    <a href={this.props.info.SiteDataitems.settings.youtube} rel='noopener noreferrer' target='_blank'>
                                        <img className="black" src={b_youtube} alt='youtube' />
                                    </a>

                                    {/* <div className="m_dansLogos">
                                        <img src={logosDuns} alt="חותם אמינות - DUN & BRADSTREET | DUNS 100 - 2021" />
                                    </div> */}
                                    <div className="dansLogos">
                                        <img src={dans1} style={{margin: '0 0 30px 10px'}} alt="חותם אמינות - DUN & BRADSTREET" />
                                        <img src={city2023} alt="מדד ההתחדשות העירונית 2023" />
                                    </div>

                                    <div className="m_bdi">
                                        <img src={logoBDI} alt="BDI CODE" />
                                    </div>


                                    
                                    
                                </div>
                                
                            </div>
                        </Container>
                    </section>

                    <div className="credit">
                        {/* <a href="http://ak-adv.co.il" target="_blank"  rel="noopener noreferrer">
                            <img src="//ak-digital.net/ak-mobile/mail_img/ak-b-hd.gif" alt="Avioz-Krispin" />
                        </a> */}
                        <p className='avisrorTxt'>אביסרור משה ובניו עבודות בניין ופיתוח בע”מ</p>
                    </div>

                </footer>
            </div>
        )
    }
}
