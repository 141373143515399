import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'; 

import logo from './../../../img/default/logoMenu.svg';

import b_facebookIcon from './../../../img/icons/black/facebook.svg';
import w_facebookIcon from './../../../img/icons/white/facebook.svg';

import b_instagram from './../../../img/icons/black/instagram.svg';
import w_instagram from './../../../img/icons/white/instagram.svg';

import b_youtube from './../../../img/icons/black/youtube.svg';
import w_youtube from './../../../img/icons/white/youtube.svg';

import b_whatsapp from './../../../img/icons/black/whatsapp.svg';
import w_whatsapp from './../../../img/icons/white/whatsapp.svg';

import b_mashkanta from './../../../img/icons/black/mashkanta.svg';
import w_mashkanta from './../../../img/icons/white/mashkanta.svg';

import b_home from './../../../img/icons/black/home.svg';
import w_home from './../../../img/icons/white/home.svg';


import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
import Dropdown from 'react-bootstrap/Dropdown'

export default class Nav extends Component {

   

  render() { 
    
    let PagesNames = {

      home: [{
        url:'/' + this.props.info.SiteDataitems.pages[0].seo.friendly,
        namePage : this.props.info.SiteDataitems.pages[0].title
      }],

      contact: [{
        url:'/' + this.props.info.SiteDataitems.pages[3].seo.friendly,
        namePage : this.props.info.SiteDataitems.pages[3].title
      }],

      projects: [{
        url:'/' + this.props.info.SiteDataitems.pages[5].seo.friendly,
        namePage : this.props.info.SiteDataitems.pages[5].title
      }],

      mishtaken: [{
        url:'/פרויקטים/משתכן',
      }],

      center: [{
        url:'/' + this.props.info.SiteDataitems.pages[7].seo.friendly,
        namePage : this.props.info.SiteDataitems.pages[7].title
      }],

      articles: [{
        url:'/' + this.props.info.SiteDataitems.pages[8].seo.friendly,
        namePage : this.props.info.SiteDataitems.pages[8].title
      }],

      about: [{
        url:'/' + this.props.info.SiteDataitems.pages[9].seo.friendly,
        namePage : this.props.info.SiteDataitems.pages[9].title
      }],

      jobs: [{
        url:'/' + this.props.info.SiteDataitems.pages[10].seo.friendly,
        namePage : this.props.info.SiteDataitems.pages[10].title
      }],

      calc: [{
        url:'/' + this.props.info.SiteDataitems.pages[11].seo.friendly,
        namePage : this.props.info.SiteDataitems.pages[11].title
      }]

    }
    
    //console.log(this.props.info.SiteDataitems.pages);

    return <div className="header-wrapper">
      <div className='site-header'>
        <div className='main-menu col'>
          <NavLink activeClassName='is-active' to="/" onClick={() => scroll.scrollTo(0)}>
            <img className='mainLogo' src={logo} alt={this.props.info.SiteDataitems.settings.sitename} />
          </NavLink>

          <NavLink className="home" activeClassName='is-active' to="/" onClick={() => scroll.scrollTo(0)}>
              <img className="black" src={b_home} alt={this.props.info.SiteDataitems.settings.sitename} />
              <img className="white" src={w_home} alt={this.props.info.SiteDataitems.settings.sitename} />
          </NavLink>

          <nav role="menu" aria-label="תפריט ראשי">
            {/* <NavLink activeClassName='is-active' to="/">{PagesNames.home[0].namePage}</NavLink> */}
            <Dropdown>
              <Dropdown.Toggle variant="default" id="dropdown-basic">פרויקטים</Dropdown.Toggle>

              <Dropdown.Menu>
                  <Dropdown.Item role="menuitem" onClick={() => scroll.scrollTo(0)} href={PagesNames.projects[0].url} >מגורים</Dropdown.Item>
                  <Dropdown.Item role="menuitem" onClick={() => scroll.scrollTo(0)} href={PagesNames.mishtaken[0].url} >למשתכן</Dropdown.Item>

                  {/*<Dropdown.Item role="menuitem" onClick={() => scroll.scrollTo(0)} href={PagesNames.center[0].url}>מסחרי</Dropdown.Item>*/}
                  {/*<Dropdown.Item href='https://lamishtaken.avisror.com/' rel='noopener noreferrer' target='_blank'>למשתכן</Dropdown.Item>*/}
              </Dropdown.Menu>
            </Dropdown>
            
            <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.center[0].url}>מסחרי</NavLink>
            <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.articles[0].url}>{PagesNames.articles[0].namePage}</NavLink>
            <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.about[0].url}>{PagesNames.about[0].namePage}</NavLink>
            <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.jobs[0].url}>{PagesNames.jobs[0].namePage}</NavLink>
            <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.contact[0].url}>{PagesNames.contact[0].namePage}</NavLink>

          </nav>
        </div>
        <div className='social col'>
          <div className="leftMenu">
              <NavLink role="menuitem" activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.calc[0].url}>
                {PagesNames.calc[0].namePage}
                <img className="black" src={b_mashkanta} alt={PagesNames.calc[0].namePage} />
                <img className="white" src={w_mashkanta} alt={PagesNames.calc[0].namePage} />
                
              </NavLink>
          </div>
          
          <a className="whatsapp" role="menuitem" href={'https://api.whatsapp.com/send?phone=' + this.props.info.SiteDataitems.settings.whatsapp} rel='noopener noreferrer' target='_blank'>
            <img className="black" src={b_whatsapp} alt='whatsapp' />
            <img className="white" src={w_whatsapp} alt='whatsapp' />
          </a>

          <a role="menuitem" href={this.props.info.SiteDataitems.settings.instagram} rel='noopener noreferrer' target='_blank'>
            <img className="black" src={b_instagram} alt='instagram' />
            <img className="white" src={w_instagram} alt='instagram' />
          </a>

          <a role="menuitem" href={this.props.info.SiteDataitems.settings.facebook} rel='noopener noreferrer' target='_blank'>
            <img className="black" src={b_facebookIcon} alt='facebook' />
            <img className="white" src={w_facebookIcon} alt='facebook' />
          </a>

          <a role="menuitem" href={this.props.info.SiteDataitems.settings.youtube} rel='noopener noreferrer' target='_blank'>
            <img className="black" src={b_youtube} alt='youtube' />
            <img className="white" src={w_youtube} alt='youtube' />
          </a>

          <span className='sitePhone'>{this.props.info.SiteDataitems.settings.sitephone}</span>
        </div>
      </div>
    </div>
  }
    

  }
