import React, { Component } from 'react'
//import ShowMap from './../projects/ShowMap';
import TextToHtml from './../../../../Components/Texthtml';
import APPIpic from '../../../APPIpic';
import ShowPopUp from "./../../ShowPopUp"

export default class Contact extends Component {
    render() {

        //console.log(this.props);

        return (
            <div className='ContactPage'>
                < ShowPopUp dataPopup={this.props.pageData.popUp} />
                {/* <ShowMap width='100%' height='400px' data={{logo:this.props.pageData.page.logoContact, name: 'אביסרור'}} lat={31.244325} long={34.798320} /> */}
                <APPIpic fullWidth={true} pic={this.props.pageData.page.bg} alt='ביניין' />

                <h1 className='hidden'>צור קשר</h1>

                <div className='text'>
                    <TextToHtml text={this.props.pageData.page.text} format=""/>
                </div>
            </div>
        )
    }
}
