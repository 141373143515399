import React, { Component } from 'react'

import CountUp from 'react-countup';
//https://www.npmjs.com/package/react-countup

import ScrollTrigger from 'react-scroll-trigger';
//https://www.npmjs.com/package/react-scroll-trigger

export default class BulletsNumbers extends Component {

    constructor(props) {

        super(props);
    
        this.state = {
           visible: false
        }
    }


    onEnterViewport = () => {

        //console.log('enter');

        this.setState({
            visible: true,
        });

    }
     
    onExitViewport = () => {
    
        //console.log('out');

        this.setState({
            
            visible: false,
        });

    }

    render() {

            let counterData = this.props.counterData;
            /* console.log(this.props.accesStates.motionOffClass); */

            //console.log(counterData);

          return (
            <ScrollTrigger onEnter={this.onEnterViewport} onExit={this.onExitViewport}>
                {
                    this.state.visible && this.props.accesStates.motionOffClass === 'motionOn' ? 
                <ul>
                    <li><strong><CountUp start={1} useEasing={true} startOnMount={true} end={counterData.dirot_counter} /></strong><br />
                    דירות</li>
                    <li><strong ><CountUp start={1} useEasing={true} startOnMount={true} end={counterData.proj_counter} /></strong><br />
                    פרויקטים</li>
                    <li><strong><CountUp start={1} useEasing={true} startOnMount={true}  end={counterData.city_counter} /></strong><br />
                    ערים</li>
                </ul> : 
                <ul>
                    <li><strong>{counterData.dirot_counter}</strong><br />
                    דירות</li>
                    <li><strong >{counterData.proj_counter}</strong><br />
                    פרויקטים</li>
                    <li><strong>{counterData.city_counter}</strong><br />
                    ערים</li>
                </ul>
                }
            </ScrollTrigger>
          );

       
    }
}
