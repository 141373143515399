import React, { Component } from 'react'
import HomeProjectCard from './../HomePage/HomeProjectCard';
//import Container from '@material-ui/core/Container';
import Dropdown from 'react-bootstrap/Dropdown'

import lupa from './../../../../img/icons/white/lupaWhite.svg';

//import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */

export default class Projects extends Component {


    constructor(props) {

        super(props);

        let startProj = props.mishtaken ?

        props.infoSite.SiteDataitems.ProjetsLow.filter(function(desc) {
            return desc.data.statusProj === 'פרויקטים למשתכן';
        }) :
        
        props.infoSite.SiteDataitems.ProjetsLow.filter(function(desc) {
            return (desc.data.statusProj === 'פרויקטים בשיווק' || desc.data.statusProj === 'קריאה אחרונה' );
        });

        this.state = {
            projectType: props.mishtaken ? 'פרויקטים למשתכן' : 'פרויקטים בשיווק',
            projectCity: 'all',
            projects: startProj
        }


        this.cities = props.infoSite.SiteDataitems.cities;
        
    }

      
      //changeProject by CITY
      updateProjectsArrray = (city, projects,projectType) => {
                
            //console.log(city);
            //console.log(projects);

            if(city !== 'all') {

                let projectsShivuk =  projects.filter(function(desc) {
                    /* && desc.data.statusProj === projectType */
                    return (desc.data.city === city && desc.data.statusProj ==="פרויקטים בשיווק" && desc.data.statusProj !=="פרויקטים למשתכן" );
                });    

                let projectsFilter =  projects.filter(function(desc) {
                    /* && desc.data.statusProj === projectType */
                    return (desc.data.city === city && desc.data.statusProj !=="פרויקטים למשתכן" );
                });                

                //console.log(projectsFilter);

                this.setState({
                    projects : projectsShivuk[0] ? projectsShivuk : projectsFilter,
                    projectCity : city,
                    projectType : 'all'
                });
            } else {

                let projectsFilter =  projects.filter(function(desc) {
                    /* && desc.data.statusProj === projectType */
                    return (desc.data.statusProj ==="פרויקטים בשיווק" );
                }); 

                this.setState({
                    projects : projectsFilter,
                    projectCity : city,
                    projectType : 'all'
                });
            }
          
      }


    //ChangeStatusProject
    editHandler = (data,ProjetsLow) => {
        
        //console.log(data);

        let projects = ProjetsLow;
        console.log(data);

        if(data) {
            if(data !== 'all') {

                //console.log(projects);
                
                let projectsFilter =  projects.filter(function(desc) {
                    return desc.data.statusProj === data;
                });
    
                this.setState({
                    projects: projectsFilter,
                    projectCity: 'all',
                    projectType: data
                  })
    
            }
    
            else {
                this.setState({
                    projects: projects,
                    projectCity: 'all',
                    projectType: data
                  })
            }
        }
        
    }



    //for MOBILE SELECT
    updateFormValue = (event) => {

        //console.log(event.target.value);

        let projectsFilter = this.props.plans.filter(function(desc) {
            return desc.data.rooms === event.target.value;
        });

        //console.log(projectsFilter)

        if(projectsFilter.length > 0) {
            this.setState({
                items : projectsFilter,
                update: true
            });
            
        } else {
            this.setState({
                items : this.props.plans,
                update: true
            });
        }
        
    }


   
    render() {
        //console.log(this.state.projectType);
        //console.log(this.props);
        let projects = this.props.infoSite.SiteDataitems.ProjetsLow;

        return (
            <div className='AllProjectsPage'>
                <nav className="navProj">
                    <Dropdown>
                        <Dropdown.Toggle variant="default" id="dropdown-basic">{this.state.projectType !== 'all' ? this.state.projectType : 'כל הפרויקטים' }</Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item className={this.state.projectType === 'פרויקטים בשיווק' ? 'active' : ''} onClick={() => this.editHandler('פרויקטים בשיווק',projects)} href='#פרויקטים בשיווק' >פרויקטים בשיווק</Dropdown.Item>
                            <Dropdown.Item className={this.state.projectType === 'פרויקטים שאוכלסו' ? 'active' : ''} onClick={() => this.editHandler('פרויקטים שאוכלסו',projects)} href='#פרויקטים שאוכלסו'>פרויקטים שאוכלסו</Dropdown.Item>
                            <Dropdown.Item className={this.state.projectType === 'פרויקטים עתידים' ? 'active' : ''} onClick={() => this.editHandler('פרויקטים עתידים',projects)} href='#פרויקטים עתידים' >פרויקטים עתידים</Dropdown.Item>
                            
                            <Dropdown.Item className={this.state.projectType === 'קריאה אחרונה' ? 'active' : ''} onClick={() => this.editHandler('קריאה אחרונה',projects)} href='#קריאה אחרונה' >קריאה אחרונה</Dropdown.Item>

                            <Dropdown.Item className={this.state.projectType === 'פרויקטים למשתכן' ? 'active' : ''} onClick={() => this.editHandler('פרויקטים למשתכן',projects)} href='#פרויקטים למשתכן' >פרויקטים למשתכן</Dropdown.Item>
                            <Dropdown.Item className={this.state.projectType === 'all' ? 'active' : ''} onClick={() => this.editHandler('all',projects)} href='#all' >כל הפרויקטים</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <span className='divider'>|</span>
                    
                    <div className='citiesNav'>
                        {/* <button className={this.state.projectCity === 'all' ? 'active' : ''} onClick={() => this.updateProjectsArrray('all',projects,this.state.projectType)} >כל הערים</button> */}
                        {/* <button key={city.id} className={this.state.projectCity === city.name ? 'active' : ''} >{city.name}</button> */}
                        {this.props.infoSite.SiteDataitems.cities.map(city => (
                             /* onClick={() => this.updateProjectsArrray(city.name,projects,this.state.projectType)} */
                             <span key={city.id} className={this.state.projectCity === city.name ? 'active' : ''} >{city.name}</span>
                            
                        ))}
                    </div>
                            
                     {/* <div className='citiesNavMobile'>
                        <div className ="form-group" >
                            <select className ="form-control"
                                name ="rooms" value ={ this.state.rooms }
                                onChange ={ e => this.updateProjectsArrray(e.target.value,projects,this.state.projectType)}
                                onClick={this.props.pageData.page.method==="projects" ? scroll.scrollTo(0) :  void(0) }
                                >

                                <option value=''>בחר עיר</option>
                                <option value='all'>כל הערים</option>
                            { this.props.infoSite.SiteDataitems.cities.map(city => 
                                    < option value ={ city.name } key ={ city.id }> 
                                    { city.name }
                                    </ option >
                                )}
                            </select >
                        </div >
                     </div> */}

                     <img src={lupa} alt='lupa' className="Mobilelupa" />

                    
                </nav>

                {/* {console.log(this.state.projects)}     */}
                <div className='allCards'>
                    <HomeProjectCard type={this.state.projectType} projects={this.state.projects} {...this.props} />
                </div>

                    

                
            </div>
        )
      
    }
}
