import React, { Component } from "react";
import { FormValidator } from "./FormValidator";
import { ValidationMessage } from "./ValidationMessage";
import { ValidateForm } from "./wholeFormValidation";
import Alert  from './../Alert';



import MaterialForms from "./MaterialForms";


export default class Editor extends Component {
 

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "", 
            phone: "",
            projId: this.props.pageData.project ? this.props.pageData.project.info.data.id : '',
            centerProjId: '',
            /* terms: false */
        }

        if(this.props.center) {
            
            this.rules = {
                name: { required: true, minlength: 3 },
                //email: { required: true, email: true},
                phone: { required: true, minlength: 9, numbers: true},
                projId: { required: false },
                centerProjId: { required: true }
                /* terms: { true: true} */
            }
        }

        else {
            this.rules = {
                name: { required: true, minlength: 3 },
                //email: { required: true, email: true},
                phone: { required: true, minlength: 9, numbers: true},
                projId: { required: true },
                /* terms: { true: true} */
            }
        }

        
    }

    updateFormValue = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    updateFormValueOptions = (event) => { 
        let options = [...event.target.options]
            .filter(o => o.selected).map( o => o.value);
            this.setState({ [event.target.name]: options },
                () => this.props.submit(this.state));
    }

    updateFormValueCheck = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    }


    showProjectsSelect = (data) => {

        let ProjetsLow = this.props.infoSite.SiteDataitems.ProjetsLow;
        let CenterProjetsLow = this.props.infoSite.SiteDataitems.CenterProjetsLow;

        let projectsShivuk =  ProjetsLow.filter(function(desc) {
            return desc.data.statusProj === 'פרויקטים בשיווק' || desc.data.statusProj === 'קריאה אחרונה';
        });

        //let projectsShivuk = this.props.infoSite.SiteDataitems.cities;

        //console.log(data);
        //console.log(this.props.infoSite.SiteDataitems.ProjetsLow);
        

        if(data.showProjects) {

            //console.log(this.state.projId)

            return(
                < div className ="form-group selectFormGroup" >
                    < select className =" form-control"
                        name ="projId" value ={ this.state.projId }
                        onChange ={ this.updateFormValue } >
                        <option value = ''>בחר פרויקט*</option>
                        { projectsShivuk.map( project => 
                          < option value = { project.data.id } key ={ project.data.id }> 
                            { project.data.name + ' - ' +  project.data.city }
                          </option>
                        )}
                        {/* <option value = 'כללי'>כללי</option> */}
                    </select >
                    <ValidationMessage field="projId" />
                </ div >
            )
        } 
        
        else if(data.center) {
            
            //console.log(CenterProjetsLow);

            return(
                < div className ="form-group selectFormGroup" >
                    < select className =" form-control"
                        name ="centerProjId"
                        value ={ this.state.centerProjId }
                        onChange ={ this.updateFormValue } >
                        <option value = ''>בחר פרויקט*</option>
                        { CenterProjetsLow.map( project => 
                          < option value = { project.data.id } key ={ project.data.id }> 
                            { project.data.name + ' - ' +  project.data.city }
                          </option>
                        )}
                        {/* <option value = 'כללי'>כללי</option> */}
                    </select >
                    <ValidationMessage field="centerProjId" />
                </ div >
            )
        }
        
        else {return false;}
    }

    




    render() {

        //this.props.pageData.info.SiteDataitems.settings.contactMobilePhone

        let media =  this.props.pageData.info ? this.props.pageData.info.media : this.props.infoSite.media ;

        if(!this.props.sent) {
            return <div className="form-wrapper">
                        
                        <FormValidator mobile={ media === 'mobile' ? true : false } phone4Mobile={'*5508'} data={ this.state } rules={ this.rules } 
                                submit={ this.props.submit }
                                validateForm={ ValidateForm }>

                                <ValidationMessage field="form" />

                                {
                                    this.props.showProjects ? false : 

                                    <input className="form-control"
                                    name="projId"
                                    type="hidden"
                                    value={ this.state.projId } 
                                    />
                                }
                            
                                
                                
                            
                            <MaterialForms validationMessage={<ValidationMessage field="name" />} inputName='name' inputLabel='שם*' inputValue={this.state.name} inputEvent={ this.updateFormValue } />
                            <MaterialForms validationMessage={<ValidationMessage field="phone" />} inputName='phone' inputLabel='טלפון*' inputValue={this.state.phone} inputEvent={ this.updateFormValue } />
                            <MaterialForms inputName='email' inputLabel='דוא״ל' inputValue={this.state.email} inputEvent={ this.updateFormValue } />
                            
                            

                            {this.showProjectsSelect(this.props)}
                           
                            {/* < div className ="form-group" >
                                < div className ="form-check" >
                                    < input className ="form-check-input"
                                    type ="checkbox" name ="terms"
                                    checked ={this.state.terms }
                                    onChange ={this.updateFormValueCheck } />
                                    < label className ="form-check-label" >
                                        Agree to terms
                                        </label >
                                </ div >
                                < ValidationMessage field ="terms" /> 
                            </ div >    */}
                            
                        </FormValidator>
                    </div>
        }
        else {


        /* console.log(localStorage.getItem('lidResponse')); */
        return   <div className="form-wrapper">
                ההודעה נשלחה בהצלחה.
                <Alert title='תודה רבה!' message='ההודעה נשלחה בהצלחה' show={true} error={false}/>
            </div>
            

        }
    }
}
