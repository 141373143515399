import React, { Component } from 'react'
import TextToHtml from './../Texthtml';

export default class ShowPopUp extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            show : true
        }
    }
    


    render() {

        let dataPopup = this.props.dataPopup;
        //console.log(dataPopup);

        return (
            <React.Fragment>
                {dataPopup && this.state.show ? <div className="ShowPopUp animated bounceIn " >

                    <div className="headerPopUp" >
                        < button onClick={ ()=>this.setState({ show : false }) } >X</button>
                    </div>

                    <TextToHtml text={dataPopup.text} format="" NameClass="textPopUp" />

                    
                    {dataPopup.link ?   <div className="link">

                                            <a  className="btnPopup" 
                                                href={ dataPopup.link }
                                                target={dataPopup.is_blankWindow === '1' ? "_blank" : "_self" }
                                                rel="noopener noreferrer">{dataPopup.linkText ? dataPopup.linkText : 'לחצו כאן' }
                                            </a>

                                        </div>: false }
                
                </div> : false }
            </React.Fragment>
            
        )
    }
}
