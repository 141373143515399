import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel' /* https://react-bootstrap.github.io/components/carousel/ */
//import APPIpic from "./APPIpic";

import APPIpic from "../../../APPIpic";
import { ConstantsNames } from "../../../Urls"
import triangle from './../../../../img/icons/triangle.svg';
import TextToHtml from './../../../Texthtml';

function picUrl(item,pic,ismobile, ConstantsNames) {

    let url = '';

    if(ismobile) {

       url = ConstantsNames.pic + item.data['m_' + pic] + '?v=' +  ConstantsNames.version;

    } else {

        url = ConstantsNames.pic + item.data[pic] + '?v=' +  ConstantsNames.version;
    }


    return "url('" + url + "')";

}

export default class UrbanRenewalItem extends Component {
    render() {

        let item = this.props.item;
        let stripColor = item.data.stripColor !== 'rgb(218, 139, 163)' ? item.data.stripColor : false; 

        return (
            <>
                {item.data.pic1 ? <section className='gallery'>

                    {/* motion === 'motionOff' ? null : 4000 */}

                    <Carousel interval={null} className='carouselProj'  indicators={ false }
                        controls={!item.data.pic2 ? false : true } >

                        {item.data.pic1 ? <Carousel.Item key={1}>
                                {!this.props.is_mobile ? <div className='picturesGal' style={{backgroundImage: picUrl(item, 'pic1', this.props.is_mobile, ConstantsNames)} } /> :
                                <APPIpic showclass='m_pic' pic={item.data.m_pic1} alt={item.data.title} /> }
                        </Carousel.Item> : false }

                        {item.data.pic2 ? <Carousel.Item key={2}>
                                {!this.props.is_mobile ? <div className='picturesGal' style={{backgroundImage: picUrl(item, 'pic2', this.props.is_mobile, ConstantsNames)} } /> :
                                <APPIpic showclass='m_pic' pic={item.data.m_pic2} alt={item.data.title} /> }
                        </Carousel.Item> : false }

                        {item.data.pic3 ? <Carousel.Item key={3}>
                                {!this.props.is_mobile ? <div className='picturesGal' style={{backgroundImage: picUrl(item, 'pic3', this.props.is_mobile, ConstantsNames)} } /> :
                                <APPIpic showclass='m_pic' pic={item.data.m_pic3} alt={item.data.title} /> }
                        </Carousel.Item> : false }

                      
                            
                    </Carousel>
                </section> : false }

                <div className="down clear">
                    <div className="flach">

                        {stripColor ? <h2 style={{ backgroundColor : stripColor }} >{item.data.title}</h2> :
                        <h2>{item.data.title}</h2> }

                        <img className='triangle' src={triangle} alt='משולש' />
                    </div>
                    
                    <TextToHtml text={item.data.text} NameClass='text' format=""/>
                    { item.data.logos ? <APPIpic showclass='logos' pic={item.data.logos} alt={item.data.title} /> : false }
                </div>
                
            </>
        )
    }
}
