import React, { Component } from 'react'
import TextToHtml from './../../../../Components/Texthtml';

import downMouse from './../../../../img/icons/downMouse.svg';
import UrbanRenewalItem from './../../desktop/UrbanRenewal/UrbanRenewalItem';
import logoCity from '../../../../img/default/logoCity.svg'

export default class UrbanRenewal extends Component {

    constructor(props) {

        super(props);
        
        this.state = {

            readMore : false
            
        };

    }

    render() {

        let is_mobile = this.props.info.media === 'desktop' ? false : true;
        //let pData = this.props.pageData;
        let pageGeneral = this.props.pageData.page;
        let projectsUrban = this.props.pageData.projectsUrban;

        
        //console.log(pData);
        //console.log(projectsUrban);

        return (
            <div className="projectView urbanRenewalPage animated fadeIn" >
                <header>
                        <img className='logoCity' src={logoCity} alt={pageGeneral.h1} />
                        <h1 className='hidden'>{pageGeneral.h1}</h1>
                    <hr/>
                    <div className='text'>
                        <div className={this.state.readMore ? 'less' : 'more'} >
                            <TextToHtml text={pageGeneral.generalText} NameClass="showTextGeneral" format=""/>
                            <button className="readMoreBtn" onClick={ () => this.setState({readMore : !this.state.readMore}) } >{!this.state.readMore ? 'קראו עוד' : 'סגירה'}</button>
                        </div>
                        <img className='downMouse' src={downMouse} alt='לגלול למטה' />
                    </div>
                </header>


                <div className='infoProject'>

                        {projectsUrban && projectsUrban[0].data ? <div className='urbanProjects'>
                            {projectsUrban.map(item => { 
                                
                                let classItem = item.data.pic1 ? 'is_pic' : 'no_pic';

                                if (true) return <div key={item.data.id} className={'urbanProject  clear ' + classItem }>
                                <UrbanRenewalItem item={item} is_mobile = {is_mobile} />
                            </div> 
                                else return false}) }
                        </div> : <div className='urbanProjects'><h2>אין פרויקטים</h2></div> }
                    
                </div>
                
            </div>
        )
    }
}
