import React, { Component } from 'react'
import APPIpic from '../../../APPIpic';
import { NavLink } from 'react-router-dom';

import { animateScroll as scroll, } from 'react-scroll'
import Button from '@material-ui/core/Button';

import {StatusProj} from '../../../StatusProj';

export default class HomeProjectCard extends Component {

    


    render() {

        let items = this.props.projects;

        //console.log(this.props.pageData.popUp);

        //console.log(this.state);
        
        return (
            <React.Fragment>


                <div className='items  animated fadeIn'>
                    {items.map(item => {
                        return item.data.statusProj !== 'פרויקטים למשתכן' ?
                        
                        <NavLink  onClick={() => scroll.scrollTo(0)} className='HomeProjectCard' activeClassName='is-active' key={item.data.id} to={"/פרויקטים/" + item.seo.friendly }>
                            <APPIpic pic={item.data.logo} alt={item.data.name} showclass='logo'/>
                            <APPIpic pic={item.data.smallPic} alt={item.data.name} showclass='mainPic'/>
                            <div className='text clear'>
                                {/*console.log(item)*/}
                                <h2><strong>{item.data.city} </strong>{item.data.name} <small>| {StatusProj(item.data.statusProj)}</small></h2>
                                <Button aria-label={'פרויקט: ' + item.data.name + ' - לפרטים'} variant="contained" >לחצו לפרטים ></Button>
                            </div>
                        </NavLink> : 
                        <a href={ item.data.mitakenSite ? item.data.mitakenSite : 'https://lamishtaken.avisror.com'} target="_self" rel='noopener noreferrer' className='HomeProjectCard' key={item.data.id} >
                            <APPIpic pic={item.data.logo} alt={item.data.name} showclass='logo'/>
                            <APPIpic pic={item.data.smallPic} alt={item.data.name} showclass='mainPic'/>
                            <div className='text clear'>
                                {/*console.log(item)*/}
                                <h2><strong>{item.data.city} </strong>{item.data.name} <small>| {StatusProj(item.data.statusProj)}</small></h2>
                                <Button aria-label={'פרויקט: ' + item.data.name + ' - לפרטים'} variant="contained" >לחצו לפרטים ></Button>
                            </div>
                        </a>
                    }
                    )}
                </div>
                </React.Fragment>
            
        )
    }
}
