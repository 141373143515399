import { ConstantsNames } from "./Urls"

export function getId(props) {

    // setter
    //localStorage.setItem('getUrl', data);
    
    //getter //localStorage.getItem('myData');
    
    //remove
    //localStorage.removeItem('myData');
    
    //remove all
    //localStorage.clear();
     
    /* localStorage.setItem('id', props.match.params.id);
    localStorage.setItem('path', props.location.pathname);
    localStorage.setItem('hash', props.location.hash); */

    let siteId = 'avisror';

    localStorage.setItem('path_' + siteId, ConstantsNames.base_url + props.location.pathname);

    if(props.location.search) {
      localStorage.setItem('search_' + siteId, props.location.search);
    }
    
    let query = localStorage.getItem('search_'+ siteId) ? localStorage.getItem('search_' + siteId) : '' ;

    let path = ConstantsNames.base_url + props.location.pathname + query;
    localStorage.setItem('path_'+ siteId, path);   
    
    //console.log(path);
    
    /* REDIRECTS PROJECTS */
    if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=29')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_בנווה_נוי_25','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=32')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'צמרת_אביסרור_בנווה_זאב_27','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=33')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'גרנד_אביסרור_טוביהו_28','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=35')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_על_המים_30','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=36')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_בגדרה_21','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=37')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_בבת_ים_35','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=37')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_בבת_ים_35','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=40')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_מגדלי_המדע_2_31','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=41')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_בנאות_הדרים_32','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=43')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_על_הפארק_33','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=44')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_בפארק_34','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=14')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_במערב_רחובות_17','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=29')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'אביסרור_בנווה_נוי_25','_self');
    }
    else if(props.location.pathname==='/projects.php' &&  props.location.search.includes('?id=2')) {
      window.open(ConstantsNames.base_url + ConstantsNames.projectsPageName + 'מגדלי_אביסרור_5','_self');
    }

    /* END REDIRECTS PROJECTS */

    /* return (<Test data={props} />) */ 
    return true 
    
  } 