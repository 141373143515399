import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */

import { NavLink } from 'react-router-dom'; 


import logo from './../../../img/default/logoMenu.svg';

import b_facebookIcon from './../../../img/icons/black/facebook.svg';
import b_instagram from './../../../img/icons/black/instagram.svg';
import b_youtube from './../../../img/icons/black/youtube.svg';
//import b_whatsapp from './../../../img/icons/black/whatsapp.svg';

//import Dropdown from 'react-bootstrap/Dropdown'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});



export default function Nav(props) {


  const classes = useStyles();
  const [state, setState] = React.useState({
      left: false
  });

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };


  let PagesNames = {

    home: [{
      url:'/' + props.info.SiteDataitems.pages[0].seo.friendly,
      namePage : props.info.SiteDataitems.pages[0].title
    }],

    contact: [{
      url:'/' + props.info.SiteDataitems.pages[3].seo.friendly,
      namePage : props.info.SiteDataitems.pages[3].title
    }],

    projects: [{
      url:'/' + props.info.SiteDataitems.pages[5].seo.friendly,
      namePage : props.info.SiteDataitems.pages[5].title
    }],

    mishtaken: [{
      url:'/פרויקטים/משתכן',
    }],

    center: [{
      url:'/' + props.info.SiteDataitems.pages[7].seo.friendly,
      namePage : props.info.SiteDataitems.pages[7].title
    }],

    articles: [{
      url:'/' + props.info.SiteDataitems.pages[8].seo.friendly,
      namePage : props.info.SiteDataitems.pages[8].title
    }],

    about: [{
      url:'/' + props.info.SiteDataitems.pages[9].seo.friendly,
      namePage : props.info.SiteDataitems.pages[9].title
    }],

    newCity: [{
      url:'/' + props.info.SiteDataitems.pages[13].seo.friendly,
      namePage : props.info.SiteDataitems.pages[13].title
    }],

    jobs: [{
      url:'/' + props.info.SiteDataitems.pages[10].seo.friendly,
      namePage : props.info.SiteDataitems.pages[10].title
    }],

    calc: [{
      url:'/' + props.info.SiteDataitems.pages[11].seo.friendly,
      namePage : props.info.SiteDataitems.pages[11].title
    }]

  }

  
  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      //onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>

        <ListItem>
          <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.home[0].url}>{PagesNames.home[0].namePage}</NavLink>
        </ListItem>

        <ListItem>
          <Accordion >
            <Card>

              <Card.Header>
                <Accordion.Toggle as='button' variant="link" eventKey={ 1 }>
                    פרויקטים
                    <div className="upDown" />
                </Accordion.Toggle>
                
              </Card.Header>

              <Accordion.Collapse eventKey={ 1 }>
                  <Card.Body>
                      <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.projects[0].url}>מגורים</NavLink>
                      <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.mishtaken[0].url} target="_blank" >למשתכן</NavLink>
                      {/* <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.center[0].url}>מסחרי</NavLink>*/}
                      {/* <a href='https://lamishtaken.avisror.com/' rel='noopener noreferrer' target='_blank'>למשתכן</a>*/}
                      
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </ListItem>

        <ListItem>
          <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.newCity[0].url}>{PagesNames.newCity[0].namePage}</NavLink>
        </ListItem>
          
        <ListItem>
          <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.center[0].url}>מסחרי</NavLink>
        </ListItem>
       
        <ListItem>
          <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.articles[0].url}>{PagesNames.articles[0].namePage}</NavLink>
        </ListItem>

        <ListItem>
          <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.about[0].url}>{PagesNames.about[0].namePage}</NavLink>
        </ListItem>

        

        <ListItem>
          <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.jobs[0].url}>{PagesNames.jobs[0].namePage}</NavLink>
        </ListItem>

        <ListItem>
          <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.calc[0].url}>{PagesNames.calc[0].namePage}</NavLink>
        </ListItem>

        <ListItem>
          <NavLink activeClassName='is-active' onClick={() => scroll.scrollTo(0)} to={PagesNames.contact[0].url}>{PagesNames.contact[0].namePage}</NavLink>
        </ListItem>

        
      </List>

      <Divider />

      <List>
        <div className='social'>

            <a className="phoneA" href={'tel:'+ props.info.SiteDataitems.settings.sitephone} rel='noopener noreferrer' target='_self'>
              <span className='sitePhone'>{props.info.SiteDataitems.settings.sitephone}</span>
            </a>

            {/* <a className='whatsapp' href={'https://api.whatsapp.com/send?phone=' + props.info.SiteDataitems.settings.whatsapp} rel='noopener noreferrer' target='_blank'>
                <img className="black" src={b_whatsapp} alt='whatsapp' />
            </a>
 */}
            <a href={props.info.SiteDataitems.settings.instagram} rel='noopener noreferrer' target='_blank'>
              <img className="black" src={b_instagram} alt='instagram' />
            </a>

            <a href={props.info.SiteDataitems.settings.facebook} rel='noopener noreferrer' target='_blank'>
              <img className="black" src={b_facebookIcon} alt='facebook' />
            </a>

            <a href={props.info.SiteDataitems.settings.youtube} rel='noopener noreferrer' target='_blank'>
              <img className="black" src={b_youtube} alt='youtube' />
            </a>

            
          </div>
      </List>



      {/*
      <List>
        <ListItem>2</ListItem>
      </List> */}
    </div>
  );
  
    //console.log(PagesNames.contact[0].url);

    //console.log(props);

    return (
      <div className="header-wrapper">
        <div className='site-header headerMobile'>
          
          <div className='open col'>
            <Button onClick={toggleDrawer('left', true)}>
                <div className='openIcon'>
                   <span/>
                   <span/>
                   <span/>
                </div>
            </Button>
          </div>

          <div className='main-menu col'>
            <NavLink activeClassName='is-active' to="/">
              <img className='mainLogo' src={logo} alt={props.info.SiteDataitems.settings.sitename} />
            </NavLink>
          </div>
        </div>

      <SwipeableDrawer className='mobileDraw menuRight'
          open={state.left}
          onClose={toggleDrawer('left', false)}
          onOpen={toggleDrawer('left', true)}
      >
        {sideList('left')}
      </SwipeableDrawer>
      
    </div>
  

  
  )
    

  }
