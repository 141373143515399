import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import APPIpic from '../../../APPIpic';
import TextToHtml from './../../../Texthtml';
import ShowPopUp from "./../../ShowPopUp"
import { ConstantsNames } from "../../../Urls"

export default class About extends Component {
    render() {


        let page = this.props.pageData.page;
        //console.log(page);

        let Background = ConstantsNames.pic2 + '/pages/aboutBg.jpg?v=2';

        return (
            <div className='AboutPage'>

                < ShowPopUp dataPopup={this.props.pageData.popUp} />

                <section className='aboutUp' style={{ backgroundImage: "url(" + Background + ")" }}>

                    <Container maxWidth="lg" >
                        <div className='textConont clear'>
                            <h2>{page.titleUp}</h2>
                            <TextToHtml text={page.text_up} format="" NameClass="text" />
                            <strong className='superStrong'>{page.sign}</strong>
                        </div>
                    </Container>

                </section>
                <Container maxWidth="lg" >
                    <h1 className='hidden' >{this.props.pageData.page.h1}</h1>

                    <div className='allPictures'>

                        <figure>
                            <APPIpic fullWidth={true} pic={page.pic_eli} alt='אלי אביסרור' />
                            <figcaption>אלי אביסרור<br />מנכ״ל</figcaption>
                        </figure>

                        <figure>
                            <APPIpic fullWidth={true} pic={page.pic_itzhak} alt="יצחק (ג'קי) אביסרור" />
                            <figcaption>יצחק (ג'קי) אביסרור<br />סמנכ"ל</figcaption>
                        </figure>

                        <figure>
                            <APPIpic fullWidth={true} pic={page.pic_yoram} alt='יורם אביסרור' />
                            <figcaption>יורם אביסרור<br />סמנכ"ל שיווק</figcaption>
                        </figure>

                    </div>

                    <div className='texts clear'>


                        <div className='text'>
                            <TextToHtml text={page.text1} format="" />
                        </div>


                        <div className='text'>
                            <TextToHtml text={page.text2} format="" />
                        </div>

                    </div>

                </Container>
                {page.IframeBook ? <iframe title='catalogIframe' className='catalogIframe' src={page.IframeBook} /> : false}
            </div>
        )
    }
}
