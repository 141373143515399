export function StatusProj(status) {
    if(status==='פרויקטים שאוכלסו') {
        return 'אוכלס';
    }
    else if(status==='פרויקטים בשיווק') {
        return 'בשיווק'
    }
    else if(status==='פרויקטים עתידים') {
        return 'בקרוב'
    }
    else if(status==='פרויקטים למשתכן') {
        return 'למשתכן'
    }
    else if(status==='קריאה אחרונה') {
        return 'קריאה אחרונה'
    }
    else {
        return '?'
    }
}